// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* pageTransitions.css */
.page-enter,
.page-exit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 300ms ease-in-out;
}

.page-enter {
  transform: translateX(100%);
}

.page-enter-active {
  transform: translateX(0);
}

.page-exit {
  transform: translateX(0);
}

.page-exit-active {
  transform: translateX(-100%);
}

/* 역방향 전환을 위한 클래스 */
.page-enter.reverse {
  transform: translateX(-100%);
}

.page-enter-active.reverse {
  transform: translateX(0);
}

.page-exit.reverse {
  transform: translateX(0);
}

.page-exit-active.reverse {
  transform: translateX(100%);
}
`, "",{"version":3,"sources":["webpack://./src/components/pageTransitions.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;;EAEE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,uCAAuC;AACzC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA,mBAAmB;AACnB;EACE,4BAA4B;AAC9B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":["/* pageTransitions.css */\n.page-enter,\n.page-exit {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  transition: transform 300ms ease-in-out;\n}\n\n.page-enter {\n  transform: translateX(100%);\n}\n\n.page-enter-active {\n  transform: translateX(0);\n}\n\n.page-exit {\n  transform: translateX(0);\n}\n\n.page-exit-active {\n  transform: translateX(-100%);\n}\n\n/* 역방향 전환을 위한 클래스 */\n.page-enter.reverse {\n  transform: translateX(-100%);\n}\n\n.page-enter-active.reverse {\n  transform: translateX(0);\n}\n\n.page-exit.reverse {\n  transform: translateX(0);\n}\n\n.page-exit-active.reverse {\n  transform: translateX(100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
