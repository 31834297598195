// RoulettePage.js
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Wheel } from "react-custom-roulette";
import { Button, Typography, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const emptyRoulette = [
  {
    option: "메뉴 선택",
    style: { backgroundColor: "#FFFFFF", textColor: "#000000" },
  },
];

const RoulettePage = ({ selectedCategories, resetToInitialState }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [mustSpin, setMustSpin] = useState(true);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [result, setResult] = useState(null);
  const [isSpinComplete, setIsSpinComplete] = useState(false);

  const spinWheel = useCallback(() => {
    if (selectedCategories.length > 0) {
      const newPrizeNumber = Math.floor(
        Math.random() * selectedCategories.length
      );
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
      setResult(null);
      setIsSpinComplete(false);
    }
  }, [selectedCategories]);

  useEffect(() => {
    spinWheel();
  }, [spinWheel]);

  const handleSpinClick = useCallback(() => {
    if (!mustSpin) {
      spinWheel();
    }
  }, [mustSpin, spinWheel]);

  const handleStopSpinning = useCallback(() => {
    setMustSpin(false);
    if (selectedCategories.length > 0) {
      setResult(selectedCategories[prizeNumber].option);
      setIsSpinComplete(true);
    }
  }, [selectedCategories, prizeNumber]);

  const handleReset = () => {
    resetToInitialState();
    navigate("/");
  };

  const wheelData = useMemo(() => {
    return selectedCategories.length > 0 ? selectedCategories : emptyRoulette;
  }, [selectedCategories]);

  return (
    <Box
      width="100%"
      maxWidth="600px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding={8}
    >
      <Typography
        variant="h5"
        gutterBottom
        style={{
          fontSize: isSmallScreen ? "2.5rem" : "3rem",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        {result ? result : "룰렛이 돌아가고 있습니다!"}
      </Typography>

      <Box marginBottom="20px">
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={wheelData}
          onStopSpinning={handleStopSpinning}
          outerBorderColor="#ffffff"
          outerBorderWidth={5}
          innerBorderColor="#f2f2f2"
          radiusLineColor="#dedede"
          radiusLineWidth={2}
          textDistance={60}
          fontSize={30}
          spinDuration={0.2}
          rotationCount={15}
          fontFamily='"Nanum Pen Script", cursive'
        />
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSpinClick}
        disabled={mustSpin}
        size={isSmallScreen ? "medium" : "large"}
        style={{
          fontSize: isSmallScreen ? "1.4rem" : "1.8rem",
          marginBottom: "10px",
          width: isSmallScreen ? "100%" : "auto",
        }}
      >
        {isSpinComplete ? "다시 돌리기" : "돌리는 중..."}
      </Button>

      <Button
        variant="outlined"
        color="secondary"
        onClick={handleReset}
        size={isSmallScreen ? "medium" : "large"}
        style={{
          fontSize: isSmallScreen ? "1.4rem" : "1.8rem",
          width: isSmallScreen ? "100%" : "auto",
        }}
      >
        초기화
      </Button>
    </Box>
  );
};

export default RoulettePage;
