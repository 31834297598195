// MenuSelectionPage.js
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const MenuSelectionPage = ({
  selectedCategories,
  setSelectedCategories,
  availableCategories,
  setAvailableCategories,
  customOption,
  setCustomOption,
  setError,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCategoryToggle = useCallback(
    (category) => {
      setSelectedCategories((prev) => {
        const index = prev.findIndex((cat) => cat.option === category.option);
        if (index > -1) {
          return prev.filter((cat) => cat.option !== category.option);
        } else {
          return [...prev, category];
        }
      });
    },
    [setSelectedCategories]
  );

  const handleCustomOptionChange = (event) => {
    setCustomOption(event.target.value);
  };

  const handleAddCustomOption = useCallback(() => {
    if (customOption.trim() !== "") {
      const newOption = customOption.trim();
      if (!availableCategories.some((cat) => cat.option === newOption)) {
        const { backgroundColor, textColor } = getRandomColor();
        const newCategory = {
          option: newOption,
          style: { backgroundColor, textColor },
        };
        setAvailableCategories((prev) => [...prev, newCategory]);
        setSelectedCategories((prev) => [...prev, newCategory]);
        setCustomOption("");
      } else {
        setError("이미 존재하는 메뉴입니다.");
      }
    }
  }, [
    customOption,
    availableCategories,
    setAvailableCategories,
    setSelectedCategories,
    setCustomOption,
    setError,
  ]);

  const getRandomColor = () => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = Math.floor(Math.random() * 30) + 70;
    const lightness = Math.floor(Math.random() * 20) + 40;
    const backgroundColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    const textColor = lightness > 50 ? "black" : "white";
    return { backgroundColor, textColor };
  };

  const handleStartRoulette = () => {
    if (selectedCategories.length > 0) {
      navigate("/roulette");
    } else {
      setError("메뉴를 하나 이상 선택해주세요.");
    }
  };

  return (
    <Box
      width="100%"
      maxWidth="600px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding={8}
    >
      <Typography
        variant="h5"
        gutterBottom
        style={{
          fontSize: isSmallScreen ? "2.5rem" : "3rem",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        오늘 뭐 먹을까?
      </Typography>

      <Grid
        container
        spacing={1}
        justifyContent="center"
        style={{ marginBottom: "20px" }}
      >
        {availableCategories.map((category) => (
          <Grid item key={category.option}>
            <Button
              variant={
                selectedCategories.some((cat) => cat.option === category.option)
                  ? "outlined"
                  : "text"
              }
              onClick={() => handleCategoryToggle(category)}
              style={{
                margin: "2px",
                minWidth: "auto",
                padding: "4px 8px",
                border: selectedCategories.some(
                  (cat) => cat.option === category.option
                )
                  ? "3px solid white"
                  : "none",
              }}
              size={isSmallScreen ? "small" : "medium"}
            >
              {category.option}
            </Button>
          </Grid>
        ))}
      </Grid>

      <Box
        display="flex"
        alignItems="center"
        flexDirection={isSmallScreen ? "column" : "row"}
        width="100%"
        marginBottom="20px"
      >
        <TextField
          value={customOption}
          onChange={handleCustomOptionChange}
          placeholder="새로운 메뉴 추가"
          variant="outlined"
          size={isSmallScreen ? "small" : "medium"}
          fullWidth={isSmallScreen}
          style={{ marginBottom: isSmallScreen ? "10px" : "0", flexGrow: 1 }}
        />
        <Button
          onClick={handleAddCustomOption}
          variant="outlined"
          style={{ marginLeft: isSmallScreen ? "0" : "10px" }}
          size={isSmallScreen ? "small" : "medium"}
          fullWidth={isSmallScreen}
        >
          메뉴 추가
        </Button>
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={handleStartRoulette}
        size={isSmallScreen ? "medium" : "large"}
        style={{ fontSize: isSmallScreen ? "1.4rem" : "1.8rem" }}
        fullWidth={isSmallScreen}
      >
        룰렛 돌리기
      </Button>
    </Box>
  );
};

export default MenuSelectionPage;
