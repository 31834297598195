// AdSenseInfeed.js
import React, { useEffect, useRef } from "react";

const AdSenseInfeed = () => {
  const adRef = useRef(null);
  const isAdLoaded = useRef(false);

  useEffect(() => {
    const loadAd = () => {
      if (adRef.current && !isAdLoaded.current) {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
          isAdLoaded.current = true;
        } catch (e) {
          console.error("AdSense error:", e);
        }
      }
    };

    if (window.adsbygoogle) {
      loadAd();
    } else {
      window.addEventListener("load", loadAd);
    }

    return () => {
      window.removeEventListener("load", loadAd);
      isAdLoaded.current = false;
    };
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-format="fluid"
      data-ad-layout-key="-fb+5w+4e-db+86"
      data-ad-client="ca-pub-6623500456111811"
      data-ad-slot="7802361253"
      ref={adRef}
    />
  );
};

export default React.memo(AdSenseInfeed);
