// App.js
import React from "react";
import { ThemeProvider, createTheme, CssBaseline, Box } from "@mui/material";
import Roulette from "./components/Roulette";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#ffde00",
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Roulette />
      </Box>
    </ThemeProvider>
  );
}

export default App;
