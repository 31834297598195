// Roulette.js
import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Box, ThemeProvider, createTheme, Snackbar } from "@mui/material";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import MenuSelectionPage from "./MenuSelectionPage";
import RoulettePage from "./RoulettePage";
import AdSenseInfeed from "./AdSenseInfeed";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import "./pageTransitions.css";

const theme = createTheme({
  typography: {
    fontFamily: '"Nanum Pen Script", cursive',
    allVariants: {
      fontFamily: '"Nanum Pen Script", cursive',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Nanum Pen Script", cursive',
          fontSize: "1.4rem",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: '"Nanum Pen Script", cursive',
          fontSize: "1.4rem",
        },
      },
    },
  },
});

const allCategories = [
  {
    option: "김치찌개",
    style: { backgroundColor: "#FF6B6B", textColor: "white" },
  },
  {
    option: "제육볶음",
    style: { backgroundColor: "#4ECDC4", textColor: "white" },
  },
  {
    option: "돈까스",
    style: { backgroundColor: "#45B7D1", textColor: "white" },
  },
  { option: "라면", style: { backgroundColor: "#1ABC9C", textColor: "white" } },
  {
    option: "비빔밥",
    style: { backgroundColor: "#F7DC6F", textColor: "black" },
  },
  { option: "국밥", style: { backgroundColor: "#FF8C00", textColor: "white" } },
  {
    option: "부대찌개",
    style: { backgroundColor: "#3498DB", textColor: "white" },
  },
  {
    option: "중식",
    style: { backgroundColor: "#D35400", textColor: "white" },
  },
  { option: "분식", style: { backgroundColor: "#27AE60", textColor: "white" } },
];

const Roulette = () => {
  const [selectedCategories, setSelectedCategories] = useState(
    allCategories.slice(0, 3)
  );
  const [availableCategories, setAvailableCategories] = useState(allCategories);
  const [customOption, setCustomOption] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    document.fonts.ready.then(() => {
      console.log("Fonts are loaded");
    });
  }, []);

  const resetToInitialState = useCallback(() => {
    setSelectedCategories(allCategories.slice(0, 3));
    setAvailableCategories(allCategories);
    setCustomOption("");
    setError(null);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppContent
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          availableCategories={availableCategories}
          setAvailableCategories={setAvailableCategories}
          customOption={customOption}
          setCustomOption={setCustomOption}
          error={error}
          setError={setError}
          resetToInitialState={resetToInitialState}
        />
      </Router>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </ThemeProvider>
  );
};

const AppContent = ({
  selectedCategories,
  setSelectedCategories,
  availableCategories,
  setAvailableCategories,
  customOption,
  setCustomOption,
  error,
  setError,
  resetToInitialState,
}) => {
  const location = useLocation();
  const [transitionDirection, setTransitionDirection] = useState("forward");
  const prevLocationRef = useRef(location);
  const nodeRef = useRef(null);

  useEffect(() => {
    if (prevLocationRef.current.pathname !== location.pathname) {
      const prevDepth = prevLocationRef.current.pathname.split("/").length;
      const currentDepth = location.pathname.split("/").length;

      if (currentDepth > prevDepth) {
        setTransitionDirection("forward");
      } else if (currentDepth < prevDepth) {
        setTransitionDirection("reverse");
      }
    }
    prevLocationRef.current = location;
  }, [location]);

  const getTransitionClasses = () => {
    const baseClasses = {
      enter: "page-enter",
      enterActive: "page-enter-active",
      exit: "page-exit",
      exitActive: "page-exit-active",
    };

    if (transitionDirection === "reverse") {
      return {
        enter: `${baseClasses.enter} reverse`,
        enterActive: `${baseClasses.enterActive} reverse`,
        exit: `${baseClasses.exit} reverse`,
        exitActive: `${baseClasses.exitActive} reverse`,
      };
    }

    return baseClasses;
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Box flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
        <SwitchTransition>
          <CSSTransition
            key={location.pathname}
            nodeRef={nodeRef}
            classNames={getTransitionClasses()}
            timeout={300}
          >
            <Box
              ref={nodeRef}
              flexGrow={1}
              display="flex"
              flexDirection="column"
            >
              <Routes location={location}>
                <Route
                  path="/"
                  element={
                    <MenuSelectionPage
                      selectedCategories={selectedCategories}
                      setSelectedCategories={setSelectedCategories}
                      availableCategories={availableCategories}
                      setAvailableCategories={setAvailableCategories}
                      customOption={customOption}
                      setCustomOption={setCustomOption}
                      setError={setError}
                    />
                  }
                />
                <Route
                  path="/roulette"
                  element={
                    <RoulettePage
                      selectedCategories={selectedCategories}
                      resetToInitialState={resetToInitialState}
                    />
                  }
                />
                <Route
                  path="/privacy-policy"
                  element={<PrivacyPolicyPage />} // 추가된 부분
                />
              </Routes>
            </Box>
          </CSSTransition>
        </SwitchTransition>
      </Box>
      <Box width="100%" mt={2}>
        <AdSenseInfeed />
      </Box>
    </Box>
  );
};

export default Roulette;
