import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
  // 필요에 따라 다른 테마 옵션을 추가할 수 있습니다.
});

export default theme;
