// PrivacyPolicyPage.js
import React from "react";
import { Typography, Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./PrivacyPolicyPage.css"; // 이미 생성된 CSS 파일 (선택 사항)

// 커스텀 테마 생성
const privacyTheme = createTheme({
  typography: {
    fontFamily: "Arial, sans-serif", // 원하는 폰트로 설정
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Arial, sans-serif", // 모든 Typography 컴포넌트에 적용
        },
      },
    },
  },
});

const PrivacyPolicyPage = () => {
  return (
    <ThemeProvider theme={privacyTheme}>
      <Container
        maxWidth="md"
        className="privacy-policy-container" // 추가적인 스타일링 (선택 사항)
        sx={{ padding: 4 }}
      >
        <Typography variant="h4" gutterBottom>
          개인정보처리방침
        </Typography>
        <Typography variant="body1" paragraph>
          **[오늘 뭐 먹지?]**(이하 "저희 앱")는(은) 이용자의 개인정보를
          중요시하며, 「개인정보 보호법」을 준수하고 있습니다. 저희 앱은
          개인정보보호법 제30조에 따라 개인정보처리방침을 다음과 같이
          수립·공개합니다.
        </Typography>

        <Typography variant="h5" gutterBottom>
          1. 개인정보의 처리 목적
        </Typography>
        <Typography variant="body1" paragraph>
          저희 앱은 다음의 목적을 위하여 개인정보를 처리합니다.
          <ul>
            <li>서비스 제공 및 운영</li>
            <li>고객 지원 및 문의 대응</li>
            <li>서비스 개선 및 맞춤형 서비스 제공</li>
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom>
          2. 개인정보의 처리 및 보유 기간
        </Typography>
        <Typography variant="body1" paragraph>
          저희 앱은 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터
          개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를
          처리·보유합니다.
        </Typography>

        <Typography variant="h5" gutterBottom>
          3. 개인정보의 제3자 제공
        </Typography>
        <Typography variant="body1" paragraph>
          저희 앱은 원칙적으로 이용자의 개인정보를 외부에 제공하지 않습니다.
          다만, 다음의 경우에는 예외적으로 개인정보를 제공할 수 있습니다.
          <ul>
            <li>이용자가 사전에 동의한 경우</li>
            <li>
              법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와
              방법에 따라 수사기관의 요구가 있는 경우
            </li>
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom>
          4. 개인정보처리의 위탁
        </Typography>
        <Typography variant="body1" paragraph>
          저희 앱은 원활한 서비스 제공을 위하여 다음과 같이 개인정보 처리업무를
          위탁하고 있습니다.
          <ul>
            <li>위탁업무: 서버 관리</li>
            <li>수탁업체: [수탁업체명]</li>
            <li>위탁기간: [위탁기간]</li>
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom>
          5. 정보주체의 권리, 의무 및 행사 방법
        </Typography>
        <Typography variant="body1" paragraph>
          정보주체는 저희 앱에 대해 다음과 같은 권리를 행사할 수 있습니다.
          <ul>
            <li>개인정보 열람 요구</li>
            <li>오류 등이 있을 경우 정정 요구</li>
            <li>삭제 요구</li>
            <li>처리정지 요구</li>
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom>
          6. 개인정보의 안전성 확보 조치
        </Typography>
        <Typography variant="body1" paragraph>
          저희 앱은 개인정보의 안전성을 확보하기 위하여 다음과 같은 조치를
          취하고 있습니다.
          <ul>
            <li>관리적 조치: 내부관리계획 수립 및 시행</li>
            <li>기술적 조치: 개인정보 암호화, 접근 권한 관리</li>
            <li>물리적 조치: 접근 통제 및 보안 시스템 운영</li>
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom>
          7. 개인정보 보호 책임자
        </Typography>
        <Typography variant="body1" paragraph>
          저희 앱은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
          처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 다음과 같이
          개인정보 보호 책임자를 지정하고 있습니다.
          <ul>
            <li>개인정보 보호 책임자: 담당자</li>
            <li>연락처: chozzano1@gmail.com</li>
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom>
          8. 개인정보처리방침 변경
        </Typography>
        <Typography variant="body1" paragraph>
          이 개인정보처리방침은 2024년 4월 27일부터 적용됩니다.
        </Typography>
      </Container>
    </ThemeProvider>
  );
};

export default PrivacyPolicyPage;
